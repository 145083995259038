import React from 'react';
import { useNavigate } from 'react-router-dom';
import Hero from '../components/home/Hero';
import Features from '../components/home/Features';
import Technologies from '../components/home/Technologies';

function Home() {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="space-y-20">
      <Hero onNavigate={handleNavigation} />
      <Features />
      <Technologies onNavigate={handleNavigation} />
    </div>
  );
}

export default Home;