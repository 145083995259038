import React from 'react';
import { Settings, Zap, Clock, Award } from 'lucide-react';

function Features() {
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center mb-12">
        Почему выбирают нас
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="text-center p-6">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
            <Settings className="h-8 w-8 text-primary" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Гибкий подход</h3>
          <p className="text-gray-600">
            Адаптируемся под требования каждого клиента
          </p>
        </div>

        <div className="text-center p-6">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
            <Zap className="h-8 w-8 text-secondary" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Быстрое выполнение</h3>
          <p className="text-gray-600">
            Оперативная обработка заказов и минимальные сроки производства
          </p>
        </div>

        <div className="text-center p-6">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
            <Clock className="h-8 w-8 text-primary" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Точность исполнения</h3>
          <p className="text-gray-600">
            Высокая точность печати и внимание к мельчайшим деталям
          </p>
        </div>

        <div className="text-center p-6">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
            <Award className="h-8 w-8 text-secondary" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Гарантия качества</h3>
          <p className="text-gray-600">
            Предоставляем гарантию на все выполненные работы
          </p>
        </div>
      </div>
    </section>
  );
}

export default Features;