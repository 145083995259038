import React from 'react';

export interface GalleryProject {
  id: number;
  title: string;
  description: string;
  technology: string;
  tags: string[];
  images: string[];
}

export const galleryProjects: GalleryProject[] = [
  {
    id: 1,
    title: 'BJD лиса',
    description: 'Миниатюрная bjd лисичка. Состоит из 30 деталей!',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/lisa1?updatedAt=1735937869087',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/lisa3?updatedAt=1735937869012',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/lisa2?updatedAt=1735937869127',
    ],
  },
  {
    id: 2,
    title: 'BJD куклы',
    description: 'Мастер-модели для подвижных шарнирных кукол',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/IMG_20250103_222656.jpg?updatedAt=1735937868715',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/IMG_20250103_222940.jpg?updatedAt=1735937869264',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/IMG_20250103_223021.jpg?updatedAt=1735937869088',
    ],
  },
  {
    id: 3,
    title: 'Миниатюрки',
    description: 'Различные фигурки для настольных игр',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222023.jpg?updatedAt=1735938148118',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222004.jpg?updatedAt=1735938147898',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_183042.jpg?updatedAt=1735938150114',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222048.jpg?updatedAt=1735938147929',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20230131_192631.jpg?updatedAt=1735938148289',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20230131_192640.jpg?updatedAt=1735938148360',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222213.jpg?updatedAt=1735938147973',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_234414.jpg?updatedAt=1735938148060',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222112.jpg?updatedAt=1735938147988',
    ],
  },
  {
    id: 4,
    title: 'Сувенирная фигурка',
    description: 'Сканирование и печать человеческой головы ',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183319.jpg?updatedAt=1735938046021',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183400.jpg?updatedAt=1735938045935',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183333.jpg?updatedAt=1735938045969',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183302.jpg?updatedAt=1735938045648',
    ],
  },
  {
    id: 11,
    title: 'Бизнес сувенир',
    description: 'Фирменные подставки для телефонов. Печать и полировка ',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040617%20(2).jpg?updatedAt=1736367812871',
    ],
  },
  {
    id: 6,
    title: 'Пин дракон 2022',
    description: '',
    technology: 'SLA',
    tags: ['SLA', 'покраска'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040854.jpg?updatedAt=1736368042386',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040487.jpg?updatedAt=1736368042600',
    ],
  },
  {
    id: 7,
    title: 'Кэ Цин',
    description: 'персонаж из игры Genshin Impact ',
    technology: 'SLA',
    tags: ['SLA', 'покраска'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040473.jpg?updatedAt=1736368580152',
    ],
  },
  {
    id: 8,
    title: 'Кли',
    description: 'Персонаж из игры Genshin Impact ',
    technology: 'SLA',
    tags: ['SLA', 'покраска'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040475.jpg?updatedAt=1736368828246',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040474.jpg?updatedAt=1736368828172',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040471.jpg?updatedAt=1736368828043',
    ],
  },
  {
    id: 9,
    title: 'Череп саблезубого тигра',
    description: 'Масштаб 1:1',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000041244%20(1).jpg?updatedAt=1736627026870',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040436%20(1).jpg?updatedAt=1736626639672',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040435%20(1).jpg?updatedAt=1736626639538',
    ],
  },
  {
    id: 10,
    title: 'Многоцветныя печать',
    description: 'Печать фигурок с возможностью использовать до 4х цветов',
    technology: 'FDM',
    tags: ['FDM'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000041174.jpg?updatedAt=1736627397495',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000040448%20(1).jpg?updatedAt=1736627396708',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/pokras/1000041176.jpg?updatedAt=1736627397290',
    ],
  },
  {
    id: 5,
    title: 'Подставка для наушников',
    description: 'Возможно, излишне реалистичная',
    technology: 'FDM',
    tags: ['FDM'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/1736543710412.JPG?updatedAt=1736628379248',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/1736543965674.JPG?updatedAt=1736628379293',
    ],
  },
  {
    id: 12,
    title: 'Вазочка для конфет',
    description: '',
    technology: 'FDM',
    tags: ['FDM'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/DSCF0241.JPG?updatedAt=1736628597952',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040432.jpg?updatedAt=1736628815863',
    ],
  },
  {
    id: 13,
    title: 'Прозрачные очки',
    description: 'Пешать, шлифовка, покрытие лаком. Курсовая работа заказчика',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040863.jpg?updatedAt=1736629123502',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040862.jpg?updatedAt=1736629123483',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/other/1000040864.jpg?updatedAt=1736629123494',
    ],
  },
  {
    id: 14,
    title: 'Шестерня',
    description:
      'Печать износостойким угленаполненным полиамидом для работы при высокой температуре',
    technology: 'FDM',
    tags: ['FDM'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/engineer/1000040439%20(2).jpg?updatedAt=1736629427565',
    ],
  },

  {
    id: 15,
    title: 'Дра Ко Ны',
    description: 'зверюшки для продажи на маркете DraKon ',
    technology: 'SLA',
    tags: ['SLA', 'покраска'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040852.jpg?updatedAt=1737397739886',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040842.jpg?updatedAt=1737397739950',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040480.jpg?updatedAt=1737397739877',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040844.jpg?updatedAt=1737397739863',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040479.jpg?updatedAt=1737397740056',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040836.jpg?updatedAt=1737397740267',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/dragon/1000040483.jpg?updatedAt=1737397739996',
    ],
  },
];
