import React from 'react';
import { Info } from 'lucide-react';
import ContactSection from '../components/ContactSection';

function Pricing() {
  return (
    <div className="space-y-20">
      {/* Hero Section */}
      <section className="relative h-[400px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://ik.imagekit.io/5pc35hfli/zaglavie/IMG_0862.png?updatedAt=1736344956167"
            alt="3D Printing Pricing"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
          <h1 className="text-4xl font-bold mb-4">Цены на 3D-печать</h1>
          <p className="text-xl max-w-3xl">
            Индивидуальный подход к каждому проекту для достижения наилучшего
            результата
          </p>
        </div>
      </section>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Introduction */}
        <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
          <div className="flex items-start space-x-4">
            <Info className="h-6 w-6 text-blue-500 flex-shrink-0 mt-1" />
            <div className="space-y-4">
              <p className="text-lg">
                3D печать - это технологии поиска решений. Каждый проект
                уникален: нужно учитывать материал, стиль и расположение
                поддержек, время изготовления, параметры печати, способ
                пост-обработки и многое другое.
              </p>
              <p className="text-lg">
                При выборе исполнителя очень важно понять, что именно вам
                предлагают: какой материал будет использоваться, какие принтеры,
                стиль заполнения, высота слоя, какую точность вам гарантируют. В
                зависимости от требований к конечному изделию цена может
                отличаться в несколько раз.
              </p>
              <p className="text-lg font-medium">
                Ниже приведены стартовые цены на печать. Для точного расчета
                свяжитесь с нами для поиска наилучшего решения.
              </p>
            </div>
          </div>
        </div>

        {/* Pricing Tables */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {/* FDM Pricing */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-primary/10 p-6">
              <h2 className="text-2xl font-bold">FDM печать</h2>
            </div>
            <div className="p-6">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left py-2">Материал</th>
                    <th className="text-right py-2">Цена</th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  <tr>
                    <td className="py-3">Базовые пластики (PETG, PLA)</td>
                    <td className="text-right">от 10 руб/гр</td>
                  </tr>
                  <tr>
                    <td className="py-3">FLEX и TPU</td>
                    <td className="text-right">от 15 руб/гр</td>
                  </tr>
                  <tr>
                    <td className="py-3">
                      Композитные пластики (ASA, PETG, PP, flexGF)
                    </td>
                    <td className="text-right">от 20 руб/гр</td>
                  </tr>
                  <tr>
                    <td className="py-3">
                      Супер-инженерные (PA-GF, PC с рекристализацией)
                    </td>
                    <td className="text-right">от 50 руб/гр</td>
                  </tr>
                  <tr>
                    <td className="py-3">Многоцветная печать</td>
                    <td className="text-right">от 350 руб/час</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* SLA Pricing */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-secondary/10 p-6">
              <h2 className="text-2xl font-bold">SLA печать</h2>
            </div>
            <div className="p-6">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left py-2">Материал</th>
                    <th className="text-right py-2">Цена</th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  <tr>
                    <td className="py-3">
                      Базовые полимеры (conjure rigid, anycubic eco)
                    </td>
                    <td className="text-right">от 35 руб/см³</td>
                  </tr>
                  <tr>
                    <td className="py-3">Резино и силиконоподобные смолы</td>
                    <td className="text-right">от 70 руб/см³</td>
                  </tr>
                  <tr>
                    <td className="py-3">Инженерные смолы</td>
                    <td className="text-right">от 40 руб/см³</td>
                  </tr>
                  <tr>
                    <td className="py-3">Термостойкие смолы</td>
                    <td className="text-right">от 50 руб/см³</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ContactSection />
    </div>
  );
}

export default Pricing;
