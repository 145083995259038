import React from 'react';

interface FilterProps {
  activeFilter: string;
  onFilterChange: (filter: string) => void;
}

function GalleryFilters({ activeFilter, onFilterChange }: FilterProps) {
  const filters = [
    { id: 'all', label: 'Все работы' },
    { id: 'SLA', label: 'SLA печать' },
    { id: 'FDM', label: 'FDM печать' },
    { id: 'покраска', label: 'Покраска' },
  ];

  return (
    <div className="flex flex-wrap gap-4 justify-center mb-8">
      {filters.map((filter) => (
        <button
          key={filter.id}
          onClick={() => onFilterChange(filter.id)}
          className={`px-4 py-2 rounded-full transition-colors ${
            activeFilter === filter.id
              ? 'bg-secondary text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          {filter.label}
        </button>
      ))}
    </div>
  );
}

export default GalleryFilters;