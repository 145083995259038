import React from 'react';
import { ArrowRight } from 'lucide-react';

interface TechnologiesProps {
  onNavigate: (path: string) => void;
}

function Technologies({ onNavigate }: TechnologiesProps) {
  return (
    <section className="bg-gray-100 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">
          Наши технологии
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg">
            <img
              src="https://ik.imagekit.io/5pc35hfli/zaglavie/SLA_home.jpg?updatedAt=1736264267399"
              alt="SLA Printing"
              className="w-full h-64 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-4">SLA Печать</h3>
              <p className="text-gray-600 mb-4">
                Создаем высокоточные модели. Идеально для прототипов,
                мастер-моделей и выставочных образцов.
              </p>
              <button
                onClick={() => onNavigate('/sla-printing')}
                className="inline-flex items-center text-secondary hover:text-secondary-hover"
              >
                Подробнее
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="bg-white rounded-lg overflow-hidden shadow-lg">
            <img
              src="https://ik.imagekit.io/5pc35hfli/zaglavie/IMG_20250107_182132.png?updatedAt=1736263395928"
              className="w-full h-64 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-4">FDM Печать</h3>
              <p className="text-gray-600 mb-4">
                Создаем прочные функциональные изделия из различных материалов.
                Отлично подходит для замены сломанных деталей и бюджетного
                макетирования
              </p>
              <button
                onClick={() => onNavigate('/fdm-printing')}
                className="inline-flex items-center text-secondary hover:text-secondary-hover"
              >
                Подробнее
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Technologies;
