import React from 'react';
import { ArrowRight } from 'lucide-react';

interface HeroProps {
  onNavigate: (path: string) => void;
}

function Hero({ onNavigate }: HeroProps) {
  return (
    <section className="relative h-[450px] flex items-center">
      <div className="absolute inset-0">
        <img
          src="https://ik.imagekit.io/5pc35hfli/photo_2024-12-16_15-35-46.jpg"
          alt="Imagine&Do Hero"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
        <h1 className="text-5xl font-bold mb-6">Воплощаем идеи в реальность!</h1>
        <p className="text-xl mb-8 max-w-2xl">
          Используем 3D печать и гибкий подход в работе для реализации смелых задумок
        </p>
        <div className="space-x-4">
          <button
            onClick={() => onNavigate('/gallery')}
            className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-hover text-gray-900 rounded-lg font-medium transition-colors"
          >
            Смотреть работы
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default Hero;