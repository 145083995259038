import React, { useState } from 'react';
import {
  CheckCircle2,
  ArrowRight,
  Zap,
  Target,
  Microscope,
  Cog,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import FeedbackForm from '../components/FeedbackForm';

function SLAPrinting() {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="space-y-20">
      {/* Hero Section */}
      <section className="relative h-[400px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://ik.imagekit.io/5pc35hfli/zaglavie/IMG_0862.png?updatedAt=1736344956167"
            alt="SLA Printing"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
          <h1 className="text-4xl font-bold mb-4">
            SLA (Фотополимерная) Печать
          </h1>
          <p className="text-xl max-w-2xl">
            Технология стереолитографии для создания высокоточных моделей с
            превосходным качеством поверхности
          </p>
        </div>
      </section>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Key Features Section */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">
            Ключевые преимущества SLA печати
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
                <Target className="h-8 w-8 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Высокая точность</h3>
              <p className="text-gray-600">
                Точность печати до 25 микрон позволяет воспроизводить мельчайшие
                детали
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
                <Microscope className="h-8 w-8 text-secondary" />
              </div>
              <h3 className="text-xl font-semibold mb-3">
                Качество поверхности
              </h3>
              <p className="text-gray-600">
                Гладкая поверхность без видимых слоев печати
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
                <Zap className="h-8 w-8 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Скорость печати</h3>
              <p className="text-gray-600">
                Быстрое создание прототипов и мелкосерийное производство
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
                <Cog className="h-8 w-8 text-secondary" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Универсальность</h3>
              <p className="text-gray-600">
                Широкий выбор материалов с различными свойствами
              </p>
            </div>
          </div>
        </section>

        {/* Technical Details Section */}
        <section className="mb-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="space-y-6">
              <h2 className="text-3xl font-bold mb-6">
                Технические характеристики
              </h2>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4">Параметры печати</h3>
                <ul className="space-y-3">
                  <li className="flex items-start space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-1" />
                    <span>
                      <strong>Разрешение по XY:</strong> 15-75 микрон (зависит
                      от модели принтера)
                    </span>
                  </li>
                  <li className="flex items-start space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-1" />
                    <span>
                      <strong>Толщина слоя:</strong> от 10 до 100 микрон
                    </span>
                  </li>
                  <li className="flex items-start space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-1" />
                    <span>
                      <strong>Максимальный размер:</strong> 292x165x400 мм
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="bg-gray-50 p-8 rounded-lg">
              <h3 className="text-2xl font-semibold mb-6">
                Области применения
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <h4 className="text-lg font-semibold mb-3">
                    Прототипирование
                  </h4>
                  <p className="text-gray-600">
                    Создание точных прототипов для тестирования и презентаций
                  </p>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <h4 className="text-lg font-semibold mb-3">Стоматология</h4>
                  <p className="text-gray-600">
                    Изготовление моделей, капп и хирургических шаблонов
                  </p>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <h4 className="text-lg font-semibold mb-3">Ювелирное дело</h4>
                  <p className="text-gray-600">
                    Создание выжигаемых моделей для литья
                  </p>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <h4 className="text-lg font-semibold mb-3">
                    Мелкая механика
                  </h4>
                  <p className="text-gray-600">
                    Производство высокоточных функциональных деталей
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Materials Section */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold mb-8">Материалы для печати</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="h-48 mb-4 overflow-hidden rounded-lg">
                <img
                  src="https://ik.imagekit.io/5pc35hfli/sla/ee1f760fb4be11ef81d3127876c01c29_1.jpg?updatedAt=1736349113955"
                  alt="Стандартная смола"
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-semibold mb-4">Стандартная смола</h3>
              <p className="text-gray-600 mb-4">
                Универсальный материал для широкого спектра применений
              </p>
              <ul className="space-y-2 text-sm">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Высокая детализация</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Гладкая поверхность</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Широкий диапазон цветов</span>
                </li>
              </ul>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="h-48 mb-4 overflow-hidden rounded-lg">
                <img
                  src="https://ik.imagekit.io/5pc35hfli/sla/49faed82b4bf11ef97122e6086dc6f13_1.jpg?updatedAt=1736349113833"
                  alt="Высокотемпературная смола"
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-semibold mb-4">
                Высокотемпературная смола
              </h3>
              <p className="text-gray-600 mb-4">
                Для изделий, работающих при повышенных температурах
              </p>
              <ul className="space-y-2 text-sm">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Термостойкость до 200°C</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Высокая жёсткость</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Стабильность размеров</span>
                </li>
              </ul>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="h-48 mb-4 overflow-hidden rounded-lg">
                <img
                  src="https://ik.imagekit.io/5pc35hfli/sla/1000041077.jpg?updatedAt=1736367242834"
                  alt="Гибкая смола"
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-semibold mb-4">Гибкая смола</h3>
              <p className="text-gray-600 mb-4">
                Для эластичных и резиноподобных изделий
              </p>
              <ul className="space-y-2 text-sm">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Высокая эластичность</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Устойчивость к деформациям</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                  <span>Имитация резины</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Process Section */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold mb-8">Процесс печати</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="text-4xl font-bold text-primary mb-4">01</div>
              <h3 className="text-xl font-semibold mb-3">Подготовка модели</h3>
              <p className="text-gray-600">
                Оптимизация 3D-модели, добавление поддержек и настройка
                параметров печати
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="text-4xl font-bold text-primary mb-4">02</div>
              <h3 className="text-xl font-semibold mb-3">Печать</h3>
              <p className="text-gray-600">
                Послойное отверждение фотополимера с помощью УФ-излучения
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="text-4xl font-bold text-primary mb-4">03</div>
              <h3 className="text-xl font-semibold mb-3">Постобработка</h3>
              <p className="text-gray-600">
                Очистка, финальное отверждение и финишная обработка поверхности
              </p>
            </div>
          </div>
        </section>

        {/* Gallery Link Section */}
        <section className="mb-20 text-center">
          <Link
            to="/gallery?filter=SLA"
            onClick={scrollToTop}
            className="inline-flex items-center px-6 py-3 bg-secondary hover:bg-secondary-hover text-white rounded-lg font-medium transition-colors"
          >
            Посмотреть примеры работ SLA печати
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </section>
      </div>

      {/* Contact Form */}
      <FeedbackForm
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        isFloating={true}
      />
    </div>
  );
}

export default SLAPrinting;