import React, { useState } from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import FeedbackForm from './FeedbackForm';

function Footer() {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  return (
    <>
      <footer className="bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Контакты</h3>
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <Phone className="h-5 w-5 text-primary" />
                  <a 
                    href="tel:+79165461616" 
                    className="hover:text-primary transition-colors"
                  >
                    +7 916 546-16-16
                  </a>
                </div>
                <div className="flex items-center space-x-2">
                  <Mail className="h-5 w-5 text-secondary" />
                  <span>info@im-do.ru</span>
                </div>
                <div className="flex items-center space-x-2">
                  <MapPin className="h-5 w-5 text-primary" />
                  <span>Москва, район Печатники</span>
                </div>
                <div className="flex items-center space-x-4 mt-4">
                  <a 
                    href="https://t.me/pechatnik1" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-white hover:text-primary transition-colors"
                  >
                    <img 
                      src="https://ik.imagekit.io/5pc35hfli/zaglavie/87413.png?updatedAt=1737400147678" 
                      alt="Telegram"
                      className="h-5 w-5"
                    />
                    <span>Telegram</span>
                  </a>
                  <a 
                    href="https://vk.com/imaginedo" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-white hover:text-primary transition-colors"
                  >
                    <img 
                      src="https://ik.imagekit.io/5pc35hfli/zaglavie/png-clipart-computer-icons-vkontakte-desktop-logo-sprite-text-logo.png?updatedAt=1737400147494" 
                      alt="VKontakte"
                      className="h-5 w-5"
                    />
                    <span>VKontakte</span>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Режим работы</h3>
              <p>Пн-Пт: 10:00 - 18:00</p>
              <p>Сб-Вс: Выходной</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">О нас</h3>
              <p className="text-gray-300">
                Imagine&Do - это команда, ориентированная на выполнение задачи
                "под ключ". Основные наши технологии - SLA и FDM печать. В
                качестве дополнительных услуг оказываем обработку, покраску,
                гальванопластику и многое другое.
              </p>
              <button
                onClick={() => setIsFeedbackOpen(true)}
                className="mt-4 px-6 py-2 bg-primary text-gray-900 rounded-lg hover:bg-primary-hover transition-colors"
              >
                Связаться с нами
              </button>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>© 2024 Imagine&Do. Все права защищены.</p>
          </div>
        </div>
      </footer>

      <FeedbackForm
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
      />
    </>
  );
}

export default Footer;