import React from 'react';

const teamMembers = [
  {
    name: 'Михаил Хлыщев',
    role: 'Руководитель проектов',
    image:
      'https://ik.imagekit.io/5pc35hfli/zaglavie/photo_2021-08-16_12-13-08.jpg?updatedAt=1737398414454',
    description:
      'Опытный руководитель с 5-летним стажем в управлении 3D-печатью. Отвечает за координацию проектов и коммуникацию с клиентами.',
  },
  {
    name: 'Антон Кузнецов',
    role: 'Ведущий дизайнер',
    image:
      'https://ik.imagekit.io/5pc35hfli/zaglavie/photo-1519085360753-af0119f7cbe7.avif?updatedAt=1737398503361',
    description:
      'Специалист по 3D-моделированию с опытом работы в различных CAD-системах. Создает и оптимизирует модели для печати.',
  },
  {
    name: 'Наталья Молчанова',
    role: 'Оператор 3D-печати',
    image:
      'https://ik.imagekit.io/5pc35hfli/zaglavie/photo-1589451320344-13f41e77d974.avif?updatedAt=1737398764242',
    description:
      'Эксперт по настройке и обслуживанию 3D-принтеров. Специализируется на работе с SLA и FDM технологиями.',
  },
];

function TeamSection() {
  return (
    <section className="py-12">
      <h2 className="text-3xl font-bold mb-12 text-center">Наша команда</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            <div className="w-40 h-40 mb-6 rounded-full overflow-hidden ring-4 ring-primary/20">
              <img
                src={member.image}
                alt={member.name}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
            <p className="text-secondary font-medium mb-3">{member.role}</p>
            <p className="text-gray-600">{member.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TeamSection;
