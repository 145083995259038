import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, MessageSquare, ChevronDown } from 'lucide-react';
import FeedbackForm from './FeedbackForm';
import Logo3DPrinter from './Logo3DPrinter';

function Navbar() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  const isActive = (path: string) => location.pathname === path;
  const isPortfolioActive = ['/gallery', '/blog'].includes(location.pathname);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  return (
    <>
      <nav className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex items-center space-x-2">
                <Logo3DPrinter className="h-8 w-8 text-primary" />
                <span className="text-xl font-bold text-gray-900">imagine&do</span>
              </Link>
            </div>
            
            {/* Mobile menu button */}
            <div className="flex items-center md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-600 hover:text-gray-900"
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
            
            {/* Desktop menu */}
            <div className="hidden md:flex items-center space-x-8">
              <Link
                to="/"
                className={`${
                  isActive('/') ? 'text-secondary' : 'text-gray-600'
                } hover:text-secondary-hover px-3 py-2 text-sm font-medium transition-colors`}
              >
                Главная
              </Link>
              <Link
                to="/sla-printing"
                className={`${
                  isActive('/sla-printing') ? 'text-secondary' : 'text-gray-600'
                } hover:text-secondary-hover px-3 py-2 text-sm font-medium transition-colors`}
              >
                SLA Печать
              </Link>
              <Link
                to="/fdm-printing"
                className={`${
                  isActive('/fdm-printing') ? 'text-secondary' : 'text-gray-600'
                } hover:text-secondary-hover px-3 py-2 text-sm font-medium transition-colors`}
              >
                FDM Печать
              </Link>
              
              {/* Portfolio Dropdown */}
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className={`${
                    isPortfolioActive ? 'text-secondary' : 'text-gray-600'
                  } hover:text-secondary-hover px-3 py-2 text-sm font-medium transition-colors flex items-center space-x-1`}
                >
                  <span>Наши работы</span>
                  <ChevronDown className={`h-4 w-4 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
                </button>
                
                {isDropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                      <Link
                        to="/gallery"
                        className={`${
                          isActive('/gallery') ? 'bg-gray-100 text-secondary' : 'text-gray-700'
                        } block px-4 py-2 text-sm hover:bg-gray-100`}
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        Галерея
                      </Link>
                      <Link
                        to="/blog"
                        className={`${
                          isActive('/blog') ? 'bg-gray-100 text-secondary' : 'text-gray-700'
                        } block px-4 py-2 text-sm hover:bg-gray-100`}
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        Блог
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              <Link
                to="/pricing"
                className={`${
                  isActive('/pricing') ? 'text-secondary' : 'text-gray-600'
                } hover:text-secondary-hover px-3 py-2 text-sm font-medium transition-colors`}
              >
                Цены
              </Link>

              <Link
                to="/about"
                className={`${
                  isActive('/about') ? 'text-secondary' : 'text-gray-600'
                } hover:text-secondary-hover px-3 py-2 text-sm font-medium transition-colors`}
              >
                О нас
              </Link>
              <button
                onClick={() => setIsFeedbackOpen(true)}
                className="flex items-center space-x-1 px-4 py-2 bg-primary text-gray-900 rounded-lg hover:bg-primary-hover transition-colors"
              >
                <MessageSquare className="h-4 w-4" />
                <span>Связаться</span>
              </button>
            </div>
          </div>
        </div>
        
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/"
                className={`${
                  isActive('/') ? 'text-secondary' : 'text-gray-600'
                } block px-3 py-2 text-base font-medium hover:text-secondary-hover`}
                onClick={() => setIsMenuOpen(false)}
              >
                Главная
              </Link>
              <Link
                to="/sla-printing"
                className={`${
                  isActive('/sla-printing') ? 'text-secondary' : 'text-gray-600'
                } block px-3 py-2 text-base font-medium hover:text-secondary-hover`}
                onClick={() => setIsMenuOpen(false)}
              >
                SLA Печать
              </Link>
              <Link
                to="/fdm-printing"
                className={`${
                  isActive('/fdm-printing') ? 'text-secondary' : 'text-gray-600'
                } block px-3 py-2 text-base font-medium hover:text-secondary-hover`}
                onClick={() => setIsMenuOpen(false)}
              >
                FDM Печать
              </Link>
              
              {/* Portfolio section in mobile menu */}
              <div className="px-3 py-2">
                <span className="block text-base font-medium text-gray-900 mb-2">
                  Наши работы
                </span>
                <div className="pl-4 space-y-2">
                  <Link
                    to="/gallery"
                    className={`${
                      isActive('/gallery') ? 'text-secondary' : 'text-gray-600'
                    } block text-sm hover:text-secondary-hover`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Галерея
                  </Link>
                  <Link
                    to="/blog"
                    className={`${
                      isActive('/blog') ? 'text-secondary' : 'text-gray-600'
                    } block text-sm hover:text-secondary-hover`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Блог
                  </Link>
                </div>
              </div>

              <Link
                to="/pricing"
                className={`${
                  isActive('/pricing') ? 'text-secondary' : 'text-gray-600'
                } block px-3 py-2 text-base font-medium hover:text-secondary-hover`}
                onClick={() => setIsMenuOpen(false)}
              >
                Цены
              </Link>

              <Link
                to="/about"
                className={`${
                  isActive('/about') ? 'text-secondary' : 'text-gray-600'
                } block px-3 py-2 text-base font-medium hover:text-secondary-hover`}
                onClick={() => setIsMenuOpen(false)}
              >
                О нас
              </Link>
              <button
                onClick={() => {
                  setIsFeedbackOpen(true);
                  setIsMenuOpen(false);
                }}
                className="flex items-center space-x-1 w-full px-3 py-2 bg-primary text-gray-900 rounded-lg hover:bg-primary-hover transition-colors"
              >
                <MessageSquare className="h-4 w-4" />
                <span>Связаться</span>
              </button>
            </div>
          </div>
        )}
      </nav>

      <FeedbackForm
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        isFloating={true}
      />
    </>
  );
}

export default Navbar;